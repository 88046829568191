@use 'libs/styles/responsive';

.checkbox {
  display: flex;
}

.checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
}

.checkbox__box {
  flex-shrink: 0;
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 0.625rem;
  background-color: var(--color-white);
  margin-right: 1.25rem;
  cursor: pointer;

  .checkbox__input:focus-visible + & {
    outline: 2px solid var(--color-blue-2);
    outline-offset: 2px;
  }
}

.checkbox__checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  font-family: var(--font-icons);
  color: var(--color-gray-text);
  font-size: 1.375rem;
  text-align: center;

  .checkbox--active &,
  .checkbox__input:checked + .checkbox__box > & {
    opacity: 1;
  }
}

.checkbox__text {
  font-family: var(--font-roboto);
}

.checkbox__label {
  font-size: 0.875rem;
  line-height: 1.28;

  @include responsive.target(lg, xl) {
    font-size: 1.125rem;
    line-height: 2rem;
    vertical-align: middle;
  }
}

.checkbox__additional-text {
  font-size: 0.875rem;
  line-height: 1.28;
  display: block;
  margin-top: 1.25rem;

  @include responsive.target(lg, xl) {
    line-height: 1.25;
    margin-top: 0.875rem;
  }
}

.checkbox__link {
  color: var(--color-white);
}
