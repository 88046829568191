@use 'config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.navigation {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: var(--color-white);
  font-family: var(--font-panton);
  font-weight: var(--fw-light);
  pointer-events: none;
  z-index: var(--layer-navigation);

  &.navigation--open {
    pointer-events: all;
  }

  @include responsive.target(lg, xl) {
    position: absolute;
    height: auto;
    z-index: calc(var(--layer-logo) + 1);
  }
}

.navigation__toggle {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  pointer-events: all;
  z-index: 10;

  @include responsive.target(lg, xl) {
    display: none;
  }
}

.navigation__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-turquoise-1);
  transform: translateX(100%);
  transition: transform ease 0.3s;
  will-change: transform;
  z-index: 0;
  max-width: var(--content-max-width);

  .navigation--open & {
    transform: none;
  }

  @include responsive.target(lg, xl) {
    transform: none;
    transition: none;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;

    .subsite-layout & {
      max-width: 69.75rem;
    }
  }
}

.navigation__logo-spacer {
  height: 5.125rem;

  @include responsive.target(lg, xl) {
    display: none;
  }
}

.navigation__scroll-container {
  padding-top: 2.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  overflow-y: auto;

  // In order for firefox to add padding to bottom of scroll container
  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 2rem;
  }

  @include responsive.target(xs) {
    .lang--it & {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @include responsive.target(lg, xl) {
    display: flex;
    overflow: visible;
    align-items: center;
    padding-top: 2.25rem;

    &::after {
      display: none;
    }

    .home-a & {
      padding-left: 4.75rem;
      padding-right: 4.75rem;
      justify-content: flex-end;
    }

    .home-b & {
      padding-left: 5.375rem;
      padding-right: 5.375rem;
      justify-content: flex-start;
    }

    .subsite-layout & {
      justify-content: flex-end;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.navigation-links {
  margin-bottom: 2.5rem;

  @include responsive.target(lg, xl) {
    margin-bottom: 0;
  }
}

.navigation-links__list {
  list-style: none;

  @include responsive.target(lg, xl) {
    display: flex;
  }
}

.navigation-links__item {
  display: block;
  margin-bottom: 0.625rem;

  .navigation--no-contact-link &.navigation-links__item--contact {
    display: none;
  }

  @include responsive.target(lg, xl) {
    margin-right: 3.5rem;
    margin-bottom: 0;

    &.navigation-links__item--mobile-only {
      display: none;
    }

    &:last-child {
      margin-right: 2.5rem;
    }
  }
}

.navigation-links__item--hidden-on-desktop {
  @include responsive.target(lg, xl) {
    display: none;
  }
}

.navigation-link {
  color: inherit;
  font-size: 2.875rem;
  text-decoration: none;
  line-height: 1.2;

  @include responsive.target(lg, xl) {
    position: relative;
    font-size: 1.75rem;
    font-family: var(--font-panton);
    font-weight: var(--fw-bold);
    pointer-events: all;

    &::after {
      position: absolute;
      content: '';
      display: block;
      bottom: -0.25rem;
      width: 100%;
      height: 0.125rem;
      background-color: var(--color-white);
      opacity: 0;
      pointer-events: none;
      transition: opacity ease 0.2s;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    @-moz-document url-prefix() {
      font-weight: 500;
    }
  }
}

.navigation__separator {
  height: 0.25rem;
  max-width: 13.75rem;
  width: 100%;
  margin-bottom: 1.25rem;
  background-color: var(--color-blue-1);

  .navigation--no-contact-link & {
    display: none;
  }

  @include responsive.target(lg, xl) {
    width: 0.125rem;
    height: 2.5rem;
    max-width: none;
    background-color: var(--color-white);
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

.navigation-languages {
  @include responsive.target(lg, xl) {
    flex-shrink: 0;
    position: relative;
    width: 2.25rem;
    height: 2.25rem;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
}

.navigation-languages__item {
  display: block;
  margin-bottom: 0.25rem;

  @include responsive.target(lg, xl) {
    width: 100%;
    margin-bottom: 1rem;
    display: none;

    .navigation-languages--open & {
      display: block;
    }

    &.navigation-languages__item--active {
      order: -1;
      display: block;
    }
  }
}

.language-button {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  color: var(--color-white);
  font-size: 1.75rem;
  line-height: 1.2;
  text-decoration: none;

  @include responsive.target(lg, xl) {
    font-size: 1.75rem;
    font-family: var(--font-panton);
    font-weight: var(--fw-bold);
    text-shadow: 0 0 2rem rgba(0, 0, 0, 0.6);
    line-height: 2.25rem;

    @-moz-document url-prefix() {
      font-weight: 500;
    }
  }
}

.language-button__text-desktop {
  display: none;

  @include responsive.target(lg, xl) {
    display: inline;
  }
}

.language-button__text-mobile {
  @include responsive.target(lg, xl) {
    display: none;
  }
}
