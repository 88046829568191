@use 'libs/styles/responsive';

.contact-form {
}

.contact-form__step-indicators {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3.75rem;

  @include responsive.target(lg, xl) {
    padding-left: 12.5rem;
    padding-right: 12.5rem;
    gap: 2rem;
    margin-bottom: 6.5rem;
  }
}

.step-indicator-button {
  border: 0;
  background: transparent;
  flex-shrink: 0;
  color: var(--color-white);
  font-size: 1.125rem;
  font-weight: var(--fw-semi-bold);
  height: 2rem;
  width: 2rem;
  clip-path: url(#diamond-shape);
  display: flex;
  justify-content: center;
  align-items: center;

  .home-a & {
    background-color: var(--color-blue-2);
  }

  .home-b & {
    background-color: var(--color-turquoise-1);
  }

  &[disabled] {
    opacity: 0.5;
  }

  @include responsive.target(lg, xl) {
    font-size: 2.6875rem;
    width: 4.375rem;
    height: 4.375rem;
  }
}

.step-indicator-line {
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 1rem;
  height: 0.125rem;

  .home-a & {
    background-color: var(--color-blue-2);
  }

  .home-b & {
    background-color: var(--color-turquoise-1);
  }
}

.contact-form-step {
  display: none;

  &.contact-form-step--active {
    display: block;
  }
}

.contact-form-step-name {
  display: contents;

  .contact-form-step--existing-customer & {
    display: none;
  }
}

.contact-form-existing-customers {
  .contact-form-step--new-customer & {
    display: none;
  }
}

.contact-form-existing-customers__title {
  font-size: 1.25rem;
  line-height: 1.1;
  margin-bottom: 0.75rem;
  font-weight: var(--fw-bold);

  @include responsive.target(lg, xl) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    max-width: 80%;
  }

  @include responsive.target(xl) {
    max-width: 60%;
  }
}

.contact-form-existing-customers__copy {
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: var(--fw-light);
  margin-bottom: 1.5rem;

  @include responsive.target(lg, xl) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    max-width: 80%;
  }

  @include responsive.target(xl) {
    max-width: 70%;
  }
}

.contact-form-existing-customers__button-row {
  display: flex;
  gap: 1rem;
}

.contact-form-step__fields {
  margin-bottom: 1rem;

  @include responsive.target(lg, xl) {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2.625rem;
  }
}

.contact-form__row {
  margin-bottom: 1rem;

  &.contact-form__row--checkbox {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(lg, xl) {
    flex-basis: 0;
    flex-grow: 2;
    flex-shrink: 1;
    margin-bottom: 0;
  }
}

.contact-form__row--half {
  width: 50%;

  @include responsive.target(lg, xl) {
    flex-grow: 1;
  }
}

.contact-form__recaptcha {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contact-form__button-row {
  display: flex;
  justify-content: space-between;

  @include responsive.target(lg, xl) {
    justify-content: flex-end;
    gap: 1rem;
  }

  .button:first-child:last-child {
    margin-left: auto;
  }
}
