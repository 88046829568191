@use 'libs/styles/responsive';

.slide-visuals {
  .home-a & {
    height: inherit;

    @include responsive.target(lg, xl) {
      transform: translate(39.25rem, 6rem) scale(1.5);
      transform-origin: 0 0;
    }
  }
}

.slide-visuals__person {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;

  .slide--a-2 & {
    z-index: 10;
  }
}

.slide-person {
  display: block;
  height: 36.25rem;

  .slide--a-1 & {
    transform: translate(27%, 1%);
  }

  .slide--a-2 & {
    transform: translate(12%, 1%);
  }

  .slide--a-3 & {
    transform: translate(31%, 1%);
  }

  .slide--a-4 & {
    transform: translate(53%, 1%);
  }
}

.slide-card {
  position: absolute;
  background-color: var(--color-white);
  color: var(--color-turquoise-1);
  border-radius: 1.125rem;
  padding-top: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0.1875rem 0.75rem rgba(0, 0, 0, 0.16);
  top: 0;
  left: 0;
  font-size: 0.75rem;
  line-height: 1.16;
  font-weight: var(--fw-semi-bold);
  display: flex;
  flex-direction: column;
  align-items: center;

  &.slide-card--a-1-1 {
    top: 4rem;
    width: 12.25rem;
    height: 142px;
  }

  &.slide-card--a-1-2 {
    top: 14.625rem;
    left: 2rem;
    width: 12.5rem;
    height: 12rem;
    padding-top: 1.25rem;
  }

  &.slide-card--a-1-3 {
    top: 23.25rem;
    left: 12.75rem;
    width: 9.25rem;
    height: 8.25rem;
    padding-top: 1rem;
  }

  &.slide-card--a-2-1 {
    top: 0;
    left: 4.25rem;
    width: 11.625rem;
    height: 8.5rem;
  }

  &.slide-card--a-2-2 {
    top: 18.25rem;
    left: 2rem;
    width: 21rem;
    height: 10.125rem;
    justify-content: center;
    padding: 0;
  }

  .slide--a-3 & {
    padding-left: 1rem;
  }

  &.slide-card--a-3-1 {
    top: 0.75rem;
    left: 1rem;
    width: 12.75rem;
    height: 7.5rem;
  }

  &.slide-card--a-3-2 {
    top: 10rem;
    left: 0rem;
    width: 12.75rem;
    height: 7.5rem;
  }

  &.slide-card--a-3-3 {
    top: 19.5rem;
    left: 4rem;
    width: 12.75rem;
    height: 7.5rem;
  }

  .slide--a-4 & {
    padding-top: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.slide-card--a-4-1 {
    top: 8.75rem;
    left: 2.5rem;
    width: 8rem;
    height: 6.625rem;
  }

  &.slide-card--a-4-2 {
    top: 16rem;
    left: 6rem;
    width: 8rem;
    height: 6.625rem;
    z-index: 1;
  }

  &.slide-card--a-4-3 {
    top: 21.5rem;
    left: 2rem;
    width: 8rem;
    height: 6.625rem;
  }
}

.slide-card__number {
  font-size: 3.5rem;
  line-height: 1.2;
  font-weight: var(--fw-semi-bold);

  .slide-card--a-1-3 & {
    font-size: 4rem;
  }

  .slide-card--a-2-1 & {
    font-size: 3.875rem;
  }
}

.slide-card__rating {
  height: 1.25rem;
  margin-bottom: 0.5rem;
}

.slide-card__doughnut {
  margin-bottom: 1rem;
}

.doughnut {
  position: relative;
  width: 100%;
}

.doughnut__diagram {
  position: relative;
  width: 7.25rem;
  height: 7.25rem;
  margin: 0 auto;
}

.doughnut__number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: var(--fw-semi-bold);
}

.doughnut__graph {
  width: inherit;
  height: inherit;
}

.doughnut__no {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.5625rem;
  font-weight: var(--fw-bold);
}

.doughnut__yes {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.5625rem;
  font-weight: var(--fw-bold);
}

.slide-card__user-count {
  width: 100%;
}

.user-count {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.user-count__number {
  font-size: 3.5rem;
  font-weight: var(--fw-semi-bold);
  line-height: 1.2;
}

.user-count__label {
  padding-bottom: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.2;
  font-weight: var(--fw-bold);
}

.slide-card__corner-icon {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
}

.slide-card__icon {
  margin-bottom: 0.5rem;
}

.slide-card__label {
  font-size: 0.75rem;
  text-align: center;
  display: block;
  width: 100%;

  .slide--a-3 & {
    text-align: left;
  }

  .slide--a-4 & {
    font-weight: var(--fw-bold);
  }
}

.slide-reference {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0.75rem;
  line-height: 1.25;
  text-align: right;

  .slide--a-1 & {
    top: 30rem;
    left: 3rem;
  }

  .slide--a-2 & {
    top: 30rem;
    left: 4rem;
  }

  .slide--a-3 & {
    top: 30rem;
    left: 5rem;
  }

  .slide--a-4 & {
    top: 30rem;
    left: 7.25rem;
  }
}

.slide-reference__company {
  display: block;
  font-weight: var(--fw-bold);
}

.slide-reference__name {
  display: block;
  font-weight: var(--fw-semi-bold);
}
