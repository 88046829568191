.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.page__main {
  flex-grow: 1;
}

.page__footer {
  flex-shrink: 0;
}
