@use 'libs/styles/responsive';

.video-section {
  position: relative;
  min-height: 100vh;
  background-color: var(--color-pink-1);
  color: var(--color-white);
  overflow: hidden;

  @include responsive.target(lg, xl) {
    min-height: auto;
  }
}

.video-section__background-shape {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  min-height: inherit;
  aspect-ratio: 1;
  background-color: var(--color-blue-2);
  clip-path: url(#diamond-shape);
  transform: translate(-40%, -18%);

  @include responsive.target(lg, xl) {
    width: 90rem;
    height: 90rem;
    transform: translate(-33%, -23%);
  }
}

.video-section__inner {
  margin: 0 auto;
  max-width: var(--content-max-width);
  min-height: inherit;
  padding-top: 5.25rem;
  padding-left: 2.125rem;
  padding-right: 2.125rem;
  padding-bottom: 4.625rem;

  @include responsive.target(lg, xl) {
    padding-top: 5rem;
    padding-bottom: 7.75rem;
    padding-left: 16.875rem;
    padding-right: 16.875rem;
  }
}

.video-section__content {
  position: relative;
  z-index: 1;
}

.video-section__title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.125rem;
  font-weight: var(--fw-bold);

  @include responsive.target(lg, xl) {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }
}

.video-section__title-line {
  display: block;

  @include responsive.target(lg, xl) {
    display: inline;
  }
}

.video-section__video {
  width: 100%;
  margin-bottom: 4.625rem;

  @include responsive.target(lg, xl) {
    margin-top: 4.5rem;
    margin-bottom: 5.875rem;
  }
}

.video-section__additional-info {
  display: flex;
  justify-content: center;
}
