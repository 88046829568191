@use 'libs/styles/responsive';

.text-field {
  position: relative;
  font-family: var(--font-roboto);

  &.text-field--error::after {
    content: '!';
    position: absolute;
    top: 0;
    right: 0.625rem;
    padding-top: 0.625rem;
    font-size: 1.125rem;
    line-height: 1.33;
    color: var(--color-red-2);
  }

  @include responsive.target(lg, xl) {
    &.text-field--error::after {
      font-size: 1.5rem;
    }
  }
}

.text-field--textarea {
}

.text-field__label {
  position: absolute;
  display: block;
  font-size: 1.125rem;
  line-height: 1.33;
  color: var(--color-blue-2);
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  pointer-events: none;

  .text-field--has-value & {
    opacity: 0;
  }

  @include responsive.target(lg, xl) {
    font-size: 1.5rem;
    line-height: 1.5;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.text-field__input {
  background-color: var(--color-white);
  width: 100%;
  font-size: 1.125rem;
  line-height: 1;
  padding-top: 0.75rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.75rem;
  border-radius: 0.625rem;
  outline: 0;
  border: 0;
  color: var(--color-gray-text);
  margin: 0;

  .text-field--textarea & {
    resize: none;
  }

  // .text-field--error & {
  //   border: 1px solid var(--color-red-1);
  // }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus-visible {
    outline: 2px solid var(--color-blue-2);
    outline-offset: 2px;
  }

  @include responsive.target(lg, xl) {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .text-field--textarea & {
      min-height: 25rem;
    }
  }
}

.text-field__hint {
  display: block;
  font-size: 0.75rem;
  color: var(--color-blue-2);
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  text-decoration: none;
  pointer-events: none;

  @include responsive.target(lg, xl) {
    font-size: 1rem;
    margin-top: 0.5rem;
    padding-left: 1rem;
  }
}
