.data-list {
  position: absolute;
  top: calc(100% + 0.375rem);
  left: 0;
  right: 0;
  z-index: 1;
  background-color: var(--color-white);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: none;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);

  &:not(:empty).data-list--show {
    display: block;
  }
}

.data-list__item {
  padding-top: 0.5rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.5rem;
  color: var(--color-black);
  font-size: 1.125rem;
  cursor: pointer;

  &.data-list__item--active {
    background-color: var(--color-gray-active);
  }
}
