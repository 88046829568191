@use 'libs/styles/responsive';

.footer {
  position: relative;
  padding-top: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3.75rem;
  background-color: var(--color-turquoise-1);
  z-index: var(--layer-footer);
  font-family: var(--font-roboto);

  @include responsive.target(lg, xl) {
    padding-top: 5rem;
    padding-bottom: 2.5rem;
  }
}

.footer__inner {
  @include responsive.target(lg, xl) {
    max-width: 69.75rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer__back-to-top {
  position: absolute;
  top: 2.5rem;
  right: 1rem;

  @include responsive.target(lg, xl) {
    display: none;
  }
}

.back-to-top {
  border: 0;
  position: relative;
  background-color: var(--color-white);
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.back-to-top__icon {
  font-family: var(--font-icons);
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1rem;
  transform: translate(-50%, -50%);
  color: var(--color-pink-3);
}

.footer__row {
  @include responsive.target(lg, xl) {
    &.footer__row--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6.25rem;
    }
  }
}

.footer__logo {
  margin-bottom: 1.25rem;

  @include responsive.target(lg, xl) {
    margin-bottom: 0;
  }
}

.footer-logo {
  fill: var(--color-white);
  max-width: 10rem;

  @include responsive.target(lg, xl) {
    max-width: none;
    height: 4.3125rem;
  }
}

.footer__address {
  margin-bottom: 2.5rem;

  @include responsive.target(lg, xl) {
    order: 1;
    margin-bottom: 0;
  }
}

.footer-text {
  color: var(--color-white);
  font-size: 0.875rem;
  line-height: 1.42;

  @include responsive.target(lg, xl) {
    font-size: 1.125rem;
    line-height: 1.33;
  }
}

.footer__icon-links {
}

.icon-links {
  display: flex;
  margin-bottom: 1.25rem;

  @include responsive.target(lg, xl) {
    margin-bottom: 0;
  }
}

.icon-links__item {
  margin-right: 0.5rem;
  width: 2.75rem;

  &:last-child {
    margin-right: 0;
  }

  @include responsive.target(lg, xl) {
    width: 3.75rem;
  }
}

.icon-link {
  display: block;
  width: inherit;
  transition: transform ease 0.2s;

  &:hover {
    transform: translateY(-0.25rem);
  }
}

.icon-link__image {
  width: inherit;
}

.footer__links {
}

.footer-links {
  @include responsive.target(lg, xl) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @include responsive.target(xl) {
    flex-wrap: nowrap;
  }
}

.footer-links__item {
  margin-bottom: 0.25rem;

  @include responsive.target(lg, xl) {
    position: relative;
    padding-right: 1rem;
    margin-right: 0.75rem;
    flex-shrink: 0;

    &::after {
      position: absolute;
      content: '|';
      right: 0;
      top: 0;
      bottom: 0;
      color: var(--color-blue-2);
      font-size: 1.125rem;
      line-height: 1.33;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;

      &::after {
        content: none;
      }
    }
  }
}

.footer-link {
  color: var(--color-blue-2);
  text-decoration: none;
  font-size: 0.875rem;
  line-height: 1.42;
  transition: color ease 0.2s;

  &:hover {
    color: var(--color-blue-2--hover);
  }

  @include responsive.target(lg, xl) {
    font-size: 1.125rem;
    line-height: 1.33;
  }
}
