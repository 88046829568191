@use 'libs/styles/responsive';

.icons-section {
  min-height: 100vh;

  .home-a & {
    background-color: var(--color-blue-2);
  }

  .home-b & {
    background-color: var(--color-turquoise-1);
  }

  @include responsive.target(lg, xl) {
    min-height: auto;
  }
}

.icons-section__inner {
  margin: 0 auto;
  max-width: var(--content-max-width);

  .home-a & {
    padding-top: 19.625rem;
    padding-bottom: 7rem;
  }

  .home-b & {
    padding-top: 8.75rem;
    padding-bottom: 7rem;
  }

  @include responsive.target(lg, xl) {
    .home-a &,
    .home-b & {
      padding-top: 12.5rem;
      padding-bottom: 0;
    }
  }
}

.icons-section__title {
  padding-left: 2.125rem;
  padding-right: 2.125rem;

  @include responsive.target(lg, xl) {
    width: 47.875rem;
    padding-left: 10rem;
  }
}

.icons-section-title {
  font-family: var(--font-panton);
  font-size: 2.125rem;
  color: var(--color-white);
  text-align: left;
  font-weight: var(--fw-bold);

  @include responsive.target(lg, xl) {
    font-size: 3.5rem;
  }
}

.icons-section__slider {
}

.icon-slider {
}

.ic__scroll-container {
  padding-top: 3.75rem;
  padding-bottom: 4rem;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include responsive.target(lg, xl) {
    padding-top: 6.75rem;

    .home-a & {
      padding-bottom: 12.875rem;
    }

    .home-b & {
      padding-bottom: 11.625rem;
    }
  }
}

.ic__items {
  display: flex;

  @include responsive.target(lg, xl) {
    justify-content: space-between;
    padding-left: 6.125rem;
    padding-right: 6.125rem;
  }
}

.ic__item {
  flex-shrink: 0;
  padding-left: 1.625rem;
  padding-right: 1.625rem;

  &:first-child {
    padding-left: 3.25rem;
  }

  &:last-child {
    padding-right: 3.25rem;
  }

  @include responsive.target(lg, xl) {
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.ic-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
  align-items: center;
  color: var(--color-white);
  font-family: var(--font-panton);
  width: 10.875rem;
}

.ic-item__top {
  filter: drop-shadow(0 0.1875rem 0.75rem rgba(0, 0, 0, 0.16));
}

.ic-item__visual {
  width: 10.25rem;
  height: 10.25rem;
  background-color: var(--color-white);
  clip-path: url(#diamond-shape);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ic-item-visual {
}
.ic-item-visual--location-pin {
  transform: translateY(5%);
  max-width: 42.42%;
  max-height: 56.36%;
}
.ic-item-visual--eye {
  transform: translateY(-5%);
  max-width: 61.81%;
  max-height: 37.57%;
}
.ic-item-visual--calendar {
  max-width: 44.84%;
  max-height: 41.81%;
}
.ic-item-visual--cart {
  transform: translate(-9%, 8%);
  max-width: 52.12%;
  max-height: 49.69%;
}
.ic-item-visual--message {
  transform: translateY(9%);
  max-width: 55.15%;
  max-height: 42.42%;
}

.ic-item__title {
  font-size: 1.6875rem;
  font-weight: var(--fw-bold);
}

.ic-item__copy {
  text-align: center;
  font-weight: var(--fw-semi-bold);
  font-size: 1.25rem;
  line-height: 1.2;
}

.ic-item__additional {
  position: absolute;
  top: 0;
  right: 50%;
  filter: drop-shadow(0 0.1875rem 0.75rem rgba(0, 0, 0, 0.16));
}

.ic-item-additional {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pink-1);
  width: 6rem;
  height: 6rem;
  clip-path: url(#diamond-shape);
  transform: translate(-19%, -28%);

  &.ic-item-additional--red {
    background-color: var(--color-red-2);
  }
}

.ic-item-additional__text {
  text-align: center;
  font-size: 1rem;
  color: var(--color-white);
  font-weight: var(--fw-bold);
  padding-left: 0.375rem;
  padding-right: 0.375rem;

  &.ic-item-additional__text--small {
    [lang='it'] & {
      font-size: 0.75rem;
      margin-bottom: -0.25rem;
    }
  }
}

.ic__indicators {
  padding-left: 3.25rem;
  padding-right: 3.25rem;

  @include responsive.target(lg, xl) {
    display: none;
  }
}

.ic-indicators {
  display: flex;
  gap: 0.375rem;
}

.ic-indicator {
  height: 0.3125rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  opacity: 0.5;
  flex-grow: 1;
  flex-shrink: 1;
  transition: opacity ease-out 0.2s;

  &.ic-indicator--active {
    opacity: 1;
  }
}
