@use 'libs/styles/responsive';

.slide {
  display: none;
  padding-top: 3.875rem;
  color: var(--color-white);
  padding-left: 2.125rem;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;

  .home-a & {
    height: 43.75rem;
  }

  @include responsive.target(lg, xl) {
    padding-top: 12rem;
    padding-left: 10.125rem;
    padding-right: 10.125rem;

    .home-a & {
      height: 55.75rem;
    }
  }
}

.slide__text {
  margin-bottom: 2rem;
  min-height: 12rem;
  user-select: none;
}

.slide__subtitle {
  display: block;
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 1.25rem;
  font-weight: var(--fw-semi-bold);

  @include responsive.target(lg, xl) {
    font-size: 1.875rem;
  }
}

.slide__title {
  width: 64%;
  font-size: 2.125rem;
  line-height: 1.05;
  margin-bottom: 1.5rem;
  font-weight: var(--fw-bold);

  @include responsive.target(lg, xl) {
    font-size: 3.5rem;
    max-width: 31.75rem;
    margin-bottom: 3.3125rem;
  }
}

.slide__copy {
  width: 90%;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: var(--fw-light);

  @include responsive.target(lg, xl) {
    font-size: 2.1875rem;
    max-width: 26.75rem;
  }
}

.slide__visuals {
  pointer-events: none;

  .home-a & {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 36.25rem;
    transform-origin: bottom right;
    transform: scale(0.75) translateX(-4rem);
  }

  @include responsive.target(lg, xl) {
    .home-a & {
      left: 0;
      height: 100%;
      transform: none;
    }

    .home-b & {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
