@use 'config';
@use 'libs/styles/responsive';

.button {
  position: relative;
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: var(--fw-bold);
  font-family: var(--font-panton);
  padding-top: 0.6375rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.6375rem;
  border-radius: 0.625rem;
  background-color: var(--color-white);
  color: var(--color-pink-3);
  text-decoration: none;
  display: inline-block;
  transition: background-color ease-out 0.15s, color ease-out 0.15s;
  text-align: center;
  border: 0;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  @-moz-document url-prefix() {
    font-weight: var(--fw-medium);
  }

  &:focus-visible {
    outline: 2px solid var(--color-blue-2);
    outline-offset: 2px;
  }

  @include responsive.target(lg, xl) {
    padding-left: 2.875rem;
    padding-right: 2.875rem;
    font-size: 1.5rem;
    line-height: 1.16;
  }
}

.button--full-width {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.button--pink {
  background-color: var(--color-pink-2);

  &:hover,
  &:focus {
    background-color: var(--color-pink-3);
    color: var(--color-white);
  }
}

.button--red {
  color: var(--color-white);
  background-color: var(--color-red-2);

  &:hover,
  &:focus {
    background-color: var(--color-red-2--hover);
  }
}

.button--flat {
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 0.05);

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
