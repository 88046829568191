@use 'libs/styles/responsive';

.slider-section {
  min-height: 100vh;

  .home-a & {
    background-color: var(--color-blue-2);
  }

  .home-b & {
    background-color: var(--color-turquoise-1);
  }

  @include responsive.target(lg, xl) {
    min-height: auto;
  }
}
