@use 'libs/styles/responsive';

.radio {
  display: flex;
  align-items: center;
}

.radio__input {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
}

.radio__box {
  flex-shrink: 0;
  position: relative;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--color-white);
  margin-right: 1.25rem;
  cursor: pointer;
  align-self: flex-start;

  .radio__input:focus-visible + & {
    outline: 2px solid var(--color-blue-2);
    outline-offset: 2px;
  }
}

.radio__checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 1.375rem;
  height: 1.375rem;
  text-align: center;
  background-color: var(--color-gray-text);
  border-radius: 50%;

  .radio--active &,
  .radio__input:checked + .radio__box > & {
    opacity: 1;
  }
}

.radio__label {
  font-family: var(--font-roboto);
  font-size: 1rem;
  line-height: 1.5;
  vertical-align: middle;

  @include responsive.target(md, xl) {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  @include responsive.target(lg, xl) {
    font-size: 1.5rem;
  }
}
