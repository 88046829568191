@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.status-check {
  position: relative;
  color: var(--color-white);
  background-color: var(--color-turquoise-1);
}

.status-check__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  z-index: 0;
  opacity: 0.3;
}

.status-check__inner {
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--content-max-width);
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 5rem;

  @include responsive.target(md, xl) {
    padding-top: 5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 8rem;
  }

  @include responsive.target(lg, xl) {
    padding-top: 6.25rem;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 6.75rem;
  }
}

.status-check__small-title {
  font-weight: fonts.$fw-semi-bold;
  margin-bottom: 0.5rem;

  @include responsive.target(md, xl) {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }
}

.status-check__title {
  font-weight: fonts.$fw-bold;
  line-height: 1.2;
  margin-bottom: 2rem;
  font-size: 2rem;
  max-width: 57rem;

  @include responsive.target(md, xl) {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }

  @include responsive.target(lg, xl) {
    font-size: 3.5rem;
    min-height: 2em;
    margin-bottom: 1rem;
  }
}

.status-check__main {
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;

  @include responsive.target(lg, xl) {
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
  }
}

.status-check__text {
  @include responsive.target(lg, xl) {
    max-width: 31rem;

    [lang='de'] & {
      max-width: 28rem;
    }
  }
}

.status-check__paragraph {
  font-size: 1.125rem;
  font-weight: fonts.$fw-light;
  line-height: 1.2;
  margin-bottom: 1rem;

  @include responsive.target(md, xl) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  @include responsive.target(lg, xl) {
    font-size: 2.25rem;
    margin-bottom: 4.5rem;
  }
}

.status-check__button {
}

.status-check__visual {
}

.status-check-visual {
  position: relative;
  width: 100%;
}

.status-check-visual__image {
  pointer-events: none;
  user-select: none;
  width: 100%;

  @include responsive.target(lg, xl) {
    width: auto;
  }
}

.status-check-visual__cta {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -50%);
}

.status-check-cta {
  position: relative;
  display: block;
  background-color: var(--color-red-2);
  border-radius: 50%;
  border: 0;
  color: var(--color-white);
  cursor: pointer;
  transition: transform cubic-bezier(0.17, 0.67, 0.46, 1.51) 0.2s;
  width: 6rem;
  height: 6rem;

  &:hover {
    transform: scale(1.1);
  }

  @include responsive.target(md, xl) {
    width: 8rem;
    height: 8rem;
  }

  @include responsive.target(lg, xl) {
    width: 12rem;
    height: 12rem;
  }
}

.status-check-cta__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-17deg);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-check-cta__visual {
  transform: rotate(17deg);
  width: 2rem;
  margin-top: -0.75rem;
  margin-bottom: 0.25rem;

  @include responsive.target(md, xl) {
    width: 2.5rem;
  }

  @include responsive.target(lg, xl) {
    width: 3.5rem;
    margin-bottom: 0.5rem;
    margin-top: -1.5rem;
  }
}

.status-check-cta__text {
  text-transform: uppercase;
  font-weight: fonts.$fw-bold;
  line-height: 1;
  text-align: center;

  [lang='fr'] & {
    font-size: 0.75rem;
  }

  @include responsive.target(md, xl) {
    font-size: 1.5rem;

    [lang='fr'] & {
      font-size: 1rem;
    }

    [lang='it'] & {
      font-size: 1.25rem;
    }
  }

  @include responsive.target(lg, xl) {
    font-size: 2.25rem;

    [lang='fr'] & {
      font-size: 1.5rem;
    }

    [lang='it'] & {
      font-size: 2rem;
    }
  }
}
