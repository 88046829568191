@use 'config';

$transition-duration: 0.2s;

.hamburger {
  --hamburger-line-height: 0.1875rem;
  --hamburger-line-gap: 0.375rem;
  position: relative;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  border-radius: 50%;
  width: 2rem;
  height: calc(
    2 * var(--hamburger-line-gap) + 3 * var(--hamburger-line-height)
  );
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--hamburger-line-gap);
}

.hamburger__line {
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: transform;
  background-color: var(--color-white);
  border-radius: 1rem;
  width: 100%;
  height: var(--hamburger-line-height);
  transform-origin: center;
  transition: transform ease $transition-duration,
    background-color ease $transition-duration,
    opacity ease $transition-duration;
}

.hamburger__line--1 {
  .hamburger--open & {
    transform: translateY(calc(#{var(--hamburger-line-gap)} + 100%))
      rotate(-45deg);
  }
}

.hamburger__line--2 {
  .hamburger--open & {
    opacity: 0;
  }
}

.hamburger__line--3 {
  .hamburger--open & {
    transform: translateY(calc((#{var(--hamburger-line-gap)} + 100%) * -1))
      rotate(45deg);
  }
}
