@use 'libs/styles/helpers';

*,
*::before,
*::after {
  font-family: inherit;
  font-weight: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include helpers.nice-fonts();
}

html,
body {
  font-family: var(--font-panton, sans-serif);
  width: 100%;
  height: 100%;
  background-color: var(--color-turquoise-1);
}
