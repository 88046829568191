@use 'libs/styles/responsive';

.subsite {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.subsite__header {
  width: 100%;
  height: 12.5rem;
  background-image: url('/static/subsites/subsite_header_1.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;

  @include responsive.target(lg, xl) {
    height: 17.5rem;
  }
}

.subsite__main {
  background-color: var(--color-white);
  flex-grow: 1;
  color: var(--color-gray-text);
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;

  @include responsive.target(md, xl) {
    padding-top: 4.5rem;
  }

  @include responsive.target(lg, xl) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

.subsite__inner {
  margin: 0 auto;
  max-width: 69.75rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @include responsive.target(md, xl) {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  @include responsive.target(lg, xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.subsite__back-button {
  margin-bottom: 3.75rem;

  @include responsive.target(lg, xl) {
    &.subsite__back-button--top {
      margin-bottom: 7.5rem;
    }

    &.subsite__back-button--bottom {
      margin-bottom: 10rem;
    }
  }
}

.subsite__separator {
  width: 100%;
  border-top: 0.25rem solid var(--color-blue-2);
  margin-bottom: 2.5rem;
}

.subsite__hashtag {
  display: block;
  font-size: 1.125rem;
  font-family: var(--font-panton);
  font-weight: var(--fw-bold);
  color: var(--color-turquoise-1);
  text-transform: uppercase;
  margin-bottom: 0.625rem;

  &::before {
    content: '#';
    display: inline-block;
    margin-right: -0.25rem;
  }

  @include responsive.target(lg, xl) {
    font-size: 1.75rem;
    margin-bottom: 1.875rem;
  }
}

.subsite__title {
  font-size: 2rem;
  font-family: var(--font-panton);
  font-weight: var(--fw-light);
  line-height: 1.125;
  margin-bottom: 2.5rem;

  @include responsive.target(lg, xl) {
    font-size: 3.5rem;
    line-height: 1.14;
  }
}

.subsite__text {
  display: block;
  font-size: 1.125rem;
  line-height: 1.44;
  margin-bottom: 5rem;

  & + .subsite__text {
    margin-top: -4rem;
  }

  @include responsive.target(lg, xl) {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.subsite__link {
  color: var(--color-gray-text);

  &:hover {
    color: var(--color-blue-2);
  }
}

.subsite__visual {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  margin-bottom: 5rem;
  text-align: center;

  @include responsive.target(lg, xl) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7.5rem;
  }
}

.subsite__image {
  width: 100%;
  box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.32);

  @include responsive.target(lg, xl) {
    max-width: 56.25rem;
  }
}

.subsite__icon {
  display: inline-block;
  font-family: var(--font-icons);
  color: var(--color-turquoise-1);
  font-size: 5rem;

  @include responsive.target(lg, xl) {
    font-size: 8rem;
  }
}

.srm__title {
  font-family: var(--font-panton);
  font-weight: var(--fw-bold);
  font-size: 1.375rem;
  line-height: 1.27;
  margin-bottom: 2rem;

  @include responsive.target(lg, xl) {
    font-size: 1.75rem;
    line-height: 1.18;
  }
}

.free-website-layout {
}

.free-website-layout__columns {
  @include responsive.target(lg, xl) {
    display: flex;
  }
}

.free-website-layout__text {
  @include responsive.target(lg, xl) {
    flex-shrink: 0;
    width: 35.625rem;
    margin-right: 5rem;
  }
}

.free-website-layout__visual {
}
