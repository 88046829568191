@use 'libs/styles/responsive';

.slide-visuals {
  .home-b & {
    position: relative;

    @include responsive.target(lg, xl) {
      position: static;
    }
  }
}

.visual {
  position: absolute;
  top: 0;
  right: 0;
  filter: drop-shadow(0 0.1875rem 0.75rem rgba(0, 0, 0, 0.16));
  transform: translate(1.6875rem, 0);

  @include responsive.target(lg, xl) {
    top: 14rem;
    right: auto;
    left: 47.625rem;
  }
}

.visual__mask {
  width: 19.25rem;
  height: 19.25rem;
  clip-path: url(#diamond-shape);
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;

  @include responsive.target(lg, xl) {
    width: 26rem;
    height: 26rem;
  }
}

.visual__icon {
  display: block;
}
