@use 'libs/styles/responsive';

.intro-b {
  position: relative;
  font-family: var(--font-helvetica-neue);
  background-color: var(--color-turquoise-1);
  color: var(--color-white);
  min-height: 100vh;

  @include responsive.target(lg, xl) {
    min-height: auto;
    background-color: var(--color-blue-2);
  }
}

.intro-b__inner {
  margin: 0 auto;
  max-width: var(--content-max-width);
}

.intro-b__logo {
}

.intro-b-logo {
  display: none;

  @include responsive.target(lg, xl) {
    position: absolute;
    top: 1.25rem;
    left: 50%;
    display: block;
    pointer-events: all;
    height: 14.25rem;
    transform: translateX(-50%);
  }
}

.intro-b__text {
  padding-top: 10.5rem;
  padding-left: 2.75rem;
  padding-right: 2.5rem;
  margin-bottom: 3rem;

  @include responsive.target(lg, xl) {
    padding-top: 14.5rem;
    padding-left: 5.375rem;
    padding-right: 5.375rem;
    padding-bottom: 4.125rem;
    margin-bottom: 0;
  }
}

.intro-b__title {
  font-size: 2.875rem;
  line-height: 1.04;
  font-weight: var(--fw-bold);
  color: var(--color-white);
  text-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  margin-bottom: 1rem;

  @include responsive.target(lg, xl) {
    font-size: 5rem;
    max-width: 37.375rem;
  }
}

.intro-b__copy {
  font-size: 1.25rem;
  line-height: 1.2;
  color: var(--color-white);
  font-weight: var(--fw-regular);
  margin-bottom: 2rem;

  @include responsive.target(lg, xl) {
    font-size: 2.75rem;
    max-width: 37.375rem;
  }
}

.intro-b__cta {
}

.intro-b__visual {
  background-color: var(--color-blue-2);
  height: 360px;

  @include responsive.target(lg, xl) {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.intro-b-visual {
  position: relative;
  overflow: hidden;
  height: inherit;

  @include responsive.target(lg, xl) {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.intro-b-visual__mask {
  height: inherit;

  @include responsive.target(lg, xl) {
    transform-origin: top left;
    transform: translateX(50%);
  }
}

.intro-b-visual__image {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;

  @include responsive.target(lg, xl) {
    position: static;
    transform-origin: top left;
    transform: translateX(-1rem);
  }
}
