@use 'libs/styles/responsive';

.disclaimer {
  color: var(--color-white);
  height: 100%;

  @include responsive.target(lg, xl) {
    display: flex;
    align-items: center;
  }
}

.disclaimer__inner {
  max-width: 50rem;
  margin: 0 auto;
  padding-top: 7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 7rem;

  @include responsive.target(lg, xl) {
    padding-top: 12rem;
    padding-bottom: 7rem;
  }
}

.disclaimer__title {
  font-size: 2rem;
  margin-bottom: 2rem;

  @include responsive.target(lg, xl) {
    font-size: 3rem;
  }
}

.disclaimer__copy {
  font-size: 1rem;
  line-height: 1.3;

  @include responsive.target(lg, xl) {
    font-size: 1.125rem;
    line-height: 1.4;
  }
}
