@use 'libs/styles/responsive';

.thank-you {
  height: 100%;
  overflow: hidden;

  .thank-you--a & {
    background-color: var(--color-blue-2);
  }

  .thank-you--b & {
    background-color: var(--color-turquoise-1);
  }
}

.thank-you__inner {
  position: relative;
  margin: 0 auto;
  max-width: var(--content-max-width);
  color: var(--color-white);
  padding-top: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 6rem;
  height: 100%;

  @include responsive.target(lg, xl) {
    padding-top: 10rem;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 12.375rem;
  }
}

.thank-you__logo {
  display: none;

  @include responsive.target(lg, xl) {
    width: 100%;
    display: block;
  }
}

.thank-you-logo {
  display: block;
  margin: 0 auto;

  @include responsive.target(lg, xl) {
    width: 8.125rem;
    height: 8.125rem;
    margin-bottom: 2rem;
  }
}

.thank-you__title {
  font-size: 2.5rem;
  font-weight: var(--fw-bold);
  margin-bottom: 1rem;

  @include responsive.target(lg, xl) {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
    max-width: 49rem;
  }
}

.thank-you__copy {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 2rem;

  @include responsive.target(lg, xl) {
    font-size: 2.1875rem;
    margin-bottom: 4rem;
    max-width: 43rem;
  }
}

.thank-you__learn-more-title {
  font-weight: var(--fw-bold);
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 1rem;

  @include responsive.target(lg, xl) {
    font-size: 2.1875rem;
    margin-bottom: 1.5rem;
  }
}

.thank-you__visual {
  display: none;

  @include responsive.target(lg, xl) {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    pointer-events: none;
  }
}

.thank-you-visual {
  display: block;

  .thank-you--a & {
    height: 49.25rem;
    transform: translate(51.6875rem, 1%);
  }

  .thank-you--b & {
    height: 37.75rem;
    transform: translate(45rem, 0);
  }
}
