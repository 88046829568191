@use 'libs/styles/responsive';

.select {
  position: relative;
  font-family: var(--font-roboto);
}

.select__label {
  position: absolute;
  font-size: 1.125rem;
  line-height: 1.33;
  color: var(--color-blue-2);
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  pointer-events: none;

  .select--has-value & {
    opacity: 0;
  }

  @include responsive.target(lg, xl) {
    font-size: 1.5rem;
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.select__input {
  width: 100%;
  font-size: 1.125rem;
  line-height: 1;
  border: 0;
  outline: 0;
  appearance: none;
  color: var(--color-gray-text);
  padding-top: 0.75rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.75rem;
  border-radius: 0.625rem;
  background-color: var(--color-white);
  height: 2.875rem;

  @include responsive.target(lg, xl) {
    font-size: 1.5rem;
    height: 3.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &:focus-visible {
    outline: 2px solid var(--color-blue-2);
    outline-offset: 2px;
  }

  .form-field--error & {
    border: 2px solid var(--color-red-2);
  }
}

.select__arrow {
  position: absolute;
  font-family: var(--font-icons);
  font-size: 1.25rem;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--color-blue-2);
}
