@use 'libs/styles/responsive';

.intro-a {
  position: relative;
  min-height: 100vh;
  background-color: var(--color-turquoise-1);
  z-index: var(--layer-intro-a);
  font-family: var(--font-helvetica-neue);

  @include responsive.target(lg, xl) {
    min-height: 53rem;
  }
}

.intro-a__inner {
  position: relative;
  padding-top: 7.75rem;
  padding-left: 2.75rem;
  padding-right: 2.5rem;
  padding-bottom: 16.5rem;
  margin: 0 auto;
  max-width: var(--content-max-width);

  @include responsive.target(lg, xl) {
    padding-top: 15.75rem;
    padding-bottom: 4.125rem;
  }
}

.intro-a__logo {
}

.intro-a-logo {
  display: none;

  @include responsive.target(lg, xl) {
    position: absolute;
    top: 1.25rem;
    left: 50%;
    display: block;
    pointer-events: all;
    height: 14.25rem;
    transform: translateX(-50%);
  }
}

.intro-a__title {
  font-size: 2.875rem;
  line-height: 1.04;
  font-weight: var(--fw-bold);
  color: var(--color-white);
  text-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  margin-bottom: 1rem;

  @include responsive.target(lg, xl) {
    max-width: 40rem;
    font-size: 4.325rem;
  }
}

.intro-a__copy {
  font-size: 1.25rem;
  line-height: 1.2;
  color: var(--color-white);
  font-weight: var(--fw-regular);
  margin-bottom: 1.25rem;

  @include responsive.target(lg, xl) {
    font-size: 2.8125rem;
    max-width: 38.375rem;
    margin-bottom: 4.625rem;
  }
}

.intro-a__cta {
  position: relative;
  z-index: 10;
}

.intro-cta {
  border: 0;
  background-color: var(--color-red-2);
  border-radius: 0.625rem;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--color-white);
  font-size: 1.25rem;
  text-transform: uppercase;
}

.intro-a__visual {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;

  @include responsive.target(md, xl) {
    bottom: 4rem;
  }

  @include responsive.target(lg, xl) {
    height: 68rem;
    top: 0;
    bottom: auto;
    pointer-events: none;
    overflow: hidden;
  }
}

.intro-a-visual {
  display: block;
  transform: translateY(50%);
  padding-left: 2.75rem;

  @include responsive.target(md, xl) {
    width: 100%;
    height: 100%;
    padding-left: 0;
  }

  @include responsive.target(lg, xl) {
    max-width: var(--content-max-width);
    margin: 0 auto;
    transform: translate(
      responsive.between_px(264px, 168px, 992px, 1512px),
      responsive.between_px(336px, 144px, 992px, 1512px)
    );
  }
}

.intro-a-visual__image {
  display: block;
  width: 31.875rem;
  height: 31.875rem;

  @include responsive.target(md, xl) {
    width: 80%;
    height: auto;
  }

  @include responsive.target(lg, xl) {
    display: block;
    height: responsive.between_px(615px, 944px, 992px, 1512px);
    width: auto;
    aspect-ratio: 1;
    position: absolute;
    right: 0;
    top: 0;
  }
}
