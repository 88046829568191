@use 'config';

:root {
  /*********** Colors ***********/
  --color-black: #000000;
  --color-white: #ffffff;

  --color-gray-text: #333333;
  --color-gray-privacy-banner: #646464;
  --color-gray-active: #e5e5e5;

  --color-turquoise-1: #005e6e;
  --color-turquoise-privacy-banner: #005978;

  --color-blue-1: #709faf;
  --color-blue-2: #7faeb6;
  --color-blue-2--hover: #d0e1e4;
  --color-blue-3: #bfd7db;
  --color-blue-4: #dce7eb;

  --color-pink-1: #f4a7a9;
  --color-pink-2: #fad4d4;
  --color-pink-3: #ea5053;

  --color-red-1: #821e3e;
  --color-red-2: #ea5053;
  --color-red-2--hover: rgba(204, 50, 53);

  /*************** Fonts ***************/
  --font-panton: #{config.$font-panton};
  --font-roboto: #{config.$font-roboto};
  --font-helvetica-neue: #{config.$font-helvetica-neue};
  --font-icons: #{config.$font-icons};

  --fw-thin: 100;
  --fw-extra-light: 200;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;
  --fw-extra-bold: 800;
  --fw-extra-heavy: 900;
  --fs-normal: normal;
  --fs-italic: italic;

  /*************** Layers ***************/
  --layer-intro-a: 70;
  --layer-footer: 80;
  --layer-logo: 90;
  --layer-privacy-banner: 95;
  --layer-navigation: 100;

  /*************** Dimensions ***************/
  --content-max-width: 1440px;
}
