@use 'libs/styles/responsive';

.contact-section {
  color: var(--color-white);
  min-height: 100vh;

  .home-a & {
    background-color: var(--color-turquoise-1);
  }

  .home-b & {
    background-color: var(--color-blue-2);
  }

  @include responsive.target(lg, xl) {
    min-height: auto;
  }
}

.contact-section__inner {
  margin: 0 auto;
  max-width: var(--content-max-width);
  padding-top: 5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 5rem;

  @include responsive.target(lg, xl) {
    padding-top: 2.75rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8rem;
  }
}

.contact-section__logo {
  display: none;

  @include responsive.target(lg, xl) {
    display: block;
    text-align: center;
    margin-bottom: 1.25rem;
  }
}

.contact-section-logo {
  width: 8.125rem;
  height: 8.125rem;
}

.contact-section__text {
  text-align: center;
  margin-bottom: 7rem;
}

.contact-section__title {
  font-size: 1.875rem;
  margin-bottom: 0.625rem;
  font-weight: var(--fw-bold);

  @include responsive.target(lg, xl) {
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }
}

.contact-section__copy {
  font-size: 1.25rem;
  font-weight: var(--fw-light);

  @include responsive.target(lg, xl) {
    font-size: 2.1875rem;
  }
}

.contact-section__form {
  margin-bottom: 4.5rem;

  @include responsive.target(lg, xl) {
    padding-left: 10rem;
    padding-right: 10rem;
    margin-bottom: 6.5rem;
  }
}
