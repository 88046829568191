@use 'config';
@use 'libs/styles/responsive';

.logo {
  position: absolute;
  z-index: var(--layer-logo);
  top: 0;
  left: 0;
  width: 100%;
  height: 5.875rem;
  pointer-events: none;

  @include responsive.target(lg, xl) {
    height: auto;
    position: absolute;
  }
}

.logo__inner {
  position: relative;
  display: block;
  text-decoration: none;
  color: transparent;

  @include responsive.target(lg, xl) {
    max-width: 69.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0.875rem;
  }
}

.logo__text-svg {
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  fill: var(--color-white);
  height: 2.875rem;
  pointer-events: all;
  transition: fill ease 0.3s, transform ease 0.3s;
  transform-origin: top left;

  .logo--reduced & {
    transform: translateY(-0.375rem) scale(0.65);
  }

  .logo--light & {
    fill: var(--color-black);
    transform: none;
  }

  @include responsive.target(lg, xl) {
    display: none;
  }
}
