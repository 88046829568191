@use 'libs/styles/responsive';

.video-box {
  position: relative;
  width: 100%;
  background-color: var(--color-white);
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }
}

.video-box__play-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform ease 0.2s;

  &:hover {
    transform: scale(1.2);
  }

  .video-box--played & {
    opacity: 0;
    pointer-events: none;
    transition: opacity ease 0.3s;
  }
}

.video-box__play-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 30;
  width: 3rem;
  height: 3rem;

  @include responsive.target(lg, xl) {
    width: 6rem;
    height: 6rem;
  }
}

.video-box__thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  .video-box--played & {
    opacity: 0;
    pointer-events: none;
    transition: opacity ease 0.3s;
  }
}

.video-box__color-overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-pink-3);
  opacity: 0.26;
}

.video-box__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  min-height: 100%;
  width: 100%;
}

.video-box__video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.video-box__outlet {
}
