@use 'libs/styles/responsive';

.slider {
  position: relative;
  min-height: inherit;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include responsive.target(lg, xl) {
    .home-b & {
      height: 52.25rem;
    }
  }
}

.slider__background-shape {
  position: absolute;
  z-index: 0;
  left: 62%;
  top: 0;
  height: 140%;
  aspect-ratio: 1;
  background-color: var(--color-pink-1);
  clip-path: url(#diamond-shape);
  transform: translateX(-60%);
  transition: background-color ease-out 0.4s;

  @include responsive.target(sm, xl) {
    left: 50%;
    width: 116.25rem;
    height: 116.25rem;
    transform: translateX(-70%);
  }
}

.slider__inner {
  margin: 0 auto;
  max-width: var(--content-max-width);
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @include responsive.target(lg, xl) {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 4.25rem;
  }
}

.slider__slides {
  position: relative;
  z-index: 10;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.slider__indicators {
  position: absolute;
  left: 0;
  bottom: 2rem;
  width: 100%;
  display: flex;
  gap: 0.375rem;
  padding-left: 5.375rem;
  padding-right: 5.375rem;
  z-index: 20;

  @include responsive.target(lg, xl) {
    position: relative;
    padding-left: 10.125rem;
    padding-right: 0;
    width: 50%;
    bottom: auto;
  }
}

.slide-indicator {
  height: 0.3125rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  opacity: 0.5;
  flex-grow: 1;
  flex-shrink: 1;
  transition: opacity ease-out 0.2s;
  cursor: pointer;

  &.slide-indicator--active {
    opacity: 1;
  }
}

.slider__controls {
  display: none;

  @include responsive.target(lg, xl) {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    padding-top: 10.25rem;
    padding-left: 3.25rem;
    padding-right: 3.25rem;
    z-index: 10;
  }
}

.slider-button {
  pointer-events: all;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  transition: opacity ease-out 0.2s;
  cursor: pointer;

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

.slider-button__arrow {
  transform-origin: center center;

  .slider-button--next & {
    transform: rotate(180deg);
  }
}
